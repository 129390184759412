import React, { useState } from 'react';
import { Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, NavItem, Row, Col, NavLink } from 'reactstrap'
import Logo from '../resources-nikker/logo-bez-pozadine.png'
import { Link } from 'react-router-dom'
import icoVert from '../resources-nikker/v1.jpg'
import icoDonji from '../resources-nikker/orm1.jpg'
import icoOgl from '../resources-nikker/og1.jpg'
import set1 from '../resources-nikker/s1.jpg'

const Navigation = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);
    return (
        <div>
            <Row>
                <Col xs='4' md='3' lg='2'>
                    <Link to="/">
                        <img src={Logo} classsName='p-5' alt="nikker" width="100%"></img>
                    </Link>
                </Col>
                <Col>
                    <Nav className="justify-content-center pt-3">
                        <Link to="/">
                            <NavItem>
                                <NavLink>
                                    <span className="nav-custom">
                                        Početna
                                    </span>

                                </NavLink>
                            </NavItem>
                        </Link>
                        <NavItem>
                            <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle nav caret>
                                    <span className="nav-custom">
                                        Proizvodi
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <Link to="/Products">
                                        <DropdownItem Header className="nav-custom">Svi proizvodi</DropdownItem>
                                    </Link>
                                    <hr />
                                    <Link to="/Setovi">
                                        <DropdownItem className="nav-custom">
                                            <img src={set1} alt="nikker" width='30%'></img>
                                            Setovi</DropdownItem>
                                    </Link>
                                    <hr />
                                    <Link to="/Vertikale">
                                        <DropdownItem className="nav-custom">
                                            <img src={icoVert} alt="nikker" width='30%'></img>
                                            Vertikale</DropdownItem>
                                    </Link>
                                    <hr />
                                    <Link to="/Ormarici">
                                        <DropdownItem className="nav-custom">
                                            <img src={icoDonji} alt="nikker" width='30%'></img>
                                            Ormarići
                                        </DropdownItem>
                                    </Link>
                                    <hr />
                                    <Link to="/Ogledala">
                                        <DropdownItem className="nav-custom">
                                            <img src={icoOgl} alt="nikker" width='30%'></img>
                                            Ogledala</DropdownItem>
                                    </Link>
                                </DropdownMenu>
                            </Dropdown>
                        </NavItem>
                        <Link to="/Contact">
                            <NavItem>
                                <NavLink>
                                    <span className="nav-custom">
                                        Kontakt
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Link>
                        <Link to="/Onama">
                            <NavItem>
                                <NavLink>
                                    <span className="nav-custom">
                                        O nama
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Link>
                    </Nav>
                </Col>
            </Row>
            <hr />
        </div>
    );
}

export default Navigation;